import { useTheme } from 'styled-components';
import { ChangeEventHandler, Children, FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/style.css';

import { DevTool } from '@hookform/devtools';
import { Button } from '@agendapro/emerald/v2';
import { useMediaQuery } from 'react-responsive';
import { Checkbox, Paragraph } from '@/UI';
import { useReservation } from '@/context/ReservationsContext/ReservationsProvider';
import { StepTracker } from '../StepTracker/StepTracker';
import { formatIdCl, validationByField } from '@/utils';
import { ClientInput, ClientSelect, ClientTextArea } from '@/UI/Molecules/ClientInputs';
import { useAuthContext, usePageContext } from '@/hooks';
import InputType from '@/utils/inputTypes';

import * as St from './ClientForm.style';
import { ClientFormProps, FormClient } from './ClientForm.types';
import { selectOptions } from './constants';
import { ClientDateSelector } from '@/UI/Molecules/ClientInputs/DateSelector';
import { ReservationsSummary } from '../ReservationsSummary';
import { devices } from '@/UI/Styles/Queries';

const ClientForm: FC<ClientFormProps> = ({ handleClose }) => {
  const { auth } = useAuthContext();
  const { companyOverview, isSalfa } = usePageContext();
  const theme = useTheme();
  const router = useRouter();
  const { t } = useTranslation();
  const { reservations, dispatch } = useReservation();
  const { companyBookingInfo, client, entity, recordHistory } = reservations;

  const getDefaultValue = (field: string, fallbackValue = '') => {
    if (isSalfa) {
      return client[field] || fallbackValue;
    }

    if (field === 'email' || field === 'phone') {
      return (!companyBookingInfo.clientExclusive && auth.userData?.[field]) || client[field] || fallbackValue;
    }

    return auth.userData?.[field] || client[field] || fallbackValue;
  };

  const {
    register,
    setValue,
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormClient>({
    mode: 'onChange',
    defaultValues: {
      firstName: getDefaultValue('firstName'),
      lastName: getDefaultValue('lastName'),
      email: getDefaultValue('email'),
      phone: getDefaultValue('phone'),
      identificationNumber: client.identificationNumber || '',
      acceptedTerms: !!isSalfa,
    },
  });
  const isXLarge = useMediaQuery({
    query: devices.XLarge,
  });

  const { policyTermActive, activeMemberships, attributes, showNotes, bookingClientAttributes } = companyBookingInfo!;
  const { countryCode } = router.query as { countryCode: string };
  const isOutCall = reservations.servicesToReserve?.some((s) => s.outcall);
  const presentsClaims = watch('recordHistory.presentsClaims');

  const acceptedTerms = watch('acceptedTerms');
  const handleIdChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const formattedId = formatIdCl(e.target.value);

    if (countryCode === 'cl') {
      setValue('identificationNumber', formattedId, { shouldValidate: true });
    } else {
      setValue('identificationNumber', e.currentTarget.value, { shouldValidate: true });
    }
  };

  useEffect(() => {
    const subscription = watch(({ acceptedTerms, recordHistory, ...rest }) => {
      dispatch({ type: 'SET_CLIENT', payload: rest });
      dispatch({
        type: 'SET_RECORD_HISTORY',
        payload: {
          body: { ...recordHistory, salfaCompanyName: entity?.body.nombre, previousMileage: entity?.body.km || 0 },
        },
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [dispatch, entity?.body.km, entity?.body.nombre, watch]);

  useEffect(() => {
    dispatch({ type: 'SET_CLIENT_VALIDATION', payload: { isValid, acceptedTerms } });
  }, [dispatch, isValid, acceptedTerms]);
  const onSubmit = () => {
    if (handleClose) {
      handleClose();
    }
  };

  const preventSpace = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  };

  const ClientInputType = (item: { clientData: string; used: boolean; required: boolean; label: string }) => {
    const { clientData: name, used, required, label } = item;

    if (!used && name !== 'address' && name !== 'identification_number') {
      return null;
    }

    const validation = required ? validationByField('simpleRequired', t) : {};

    switch (name) {
      case 'email': {
        return (
          <ClientInput
            label={`${t('CLIENT_EMAIL')}`}
            required={required}
            placeholder="juan@mail.com"
            register={register('email', {
              ...validationByField('email', t, required),
              onChange: (e) => {
                setValue('email', e.target.value.trim());
                e.target.value = e.target.value.trim();
              },
            })}
            errorMsg={errors.email?.message}
          />
        );
      }
      case 'phone': {
        return (
          <St.PhoneWrapper>
            <div className="labelContainer">
              <Paragraph className="labelInput">{`${t('CLIENT_PHONE')}`}</Paragraph>
              {required && <span className="required-span">*</span>}
            </div>
            <Controller
              control={control}
              name="phone"
              rules={validationByField('phone', t, required)}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <PhoneInput
                    localization={es}
                    country={countryCode}
                    value={value}
                    buttonClass="phoneButton"
                    inputClass="phoneInput"
                    containerClass="phoneContainer"
                    dropdownClass="dropdown"
                    onChange={onChange}
                    placeholder={t('CLIENT_PHONE_PLACEHOLDER')}
                  />
                  {countryCode === 'ar' && (
                    <Paragraph size="mdSmall" weight="light">
                      {t('CLIENT_PHONE_MESSAGE')}
                    </Paragraph>
                  )}
                  {error && (
                    <Paragraph size="small" weight="light" className="errorMsg">
                      {error?.message}
                    </Paragraph>
                  )}
                </>
              )}
            />
          </St.PhoneWrapper>
        );
      }
      case 'second_phone': {
        return (
          <St.PhoneWrapper>
            <div className="labelContainer">
              <Paragraph className="labelInput">
                {`${t('CLIENT_SECOND_PHONE')}`}
                {required && <span className="required-span">*</span>}
              </Paragraph>
            </div>
            <Controller
              control={control}
              name="secondPhone"
              rules={validationByField('phone', t, required)}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <PhoneInput
                    localization={es}
                    country={countryCode}
                    value={value}
                    buttonClass="phoneButton"
                    inputClass="phoneInput"
                    containerClass="phoneContainer"
                    dropdownClass="dropdown"
                    onChange={onChange}
                    placeholder="+56 912 332 347"
                  />
                  {error && (
                    <Paragraph size="small" weight="light" className="errorMsg">
                      {error?.message}
                    </Paragraph>
                  )}
                </>
              )}
            />
          </St.PhoneWrapper>
        );
      }
      case 'identification_number': {
        if (activeMemberships || used) {
          return (
            <ClientInput
              label={`${label || t('CLIENT_IDENTIFICATION_NUMBER')}`}
              required={required}
              placeholder={label || t('CLIENT_IDENTIFICATION_NUMBER')}
              register={register('identificationNumber', {
                ...validationByField(countryCode === 'cl' ? 'rut' : 'id', t, required),
                onChange: countryCode === 'cl' ? (e) => handleIdChange(e) : undefined,
              })}
              errorMsg={
                errors.identificationNumber?.type === 'validate'
                  ? `${t('ERRORS.VALIDATION_RUT_INVALID')}`
                  : errors?.identificationNumber?.message
              }
              onKeyPress={(e) => preventSpace(e)}
            />
          );
        }
        return null;
      }
      case 'address': {
        if (isOutCall || used) {
          return (
            <ClientInput
              label={`${t('ADDRESS')}`}
              required={required}
              placeholder={`${t('ADDRESS')}${required ? '*' : ''}`}
              register={register('address', validation)}
            />
          );
        }
        return null;
      }
      case 'birth_date': {
        return (
          <ClientDateSelector
            control={control}
            item={{ name: t('CLIENT_BIRTH_DATE'), control: 'birthDate', required }}
            rules={validation}
            isCustom={false}
          />
        );
      }
      case 'age': {
        return (
          <ClientInput
            label={`${t('CLIENT_AGE')}`}
            required={required}
            placeholder={`${t('CLIENT_AGE')}${required ? '*' : ''}`}
            type="number"
            register={register('age', { ...validation, min: 1 })}
          />
        );
      }
      case 'record_number': {
        return (
          <ClientInput
            label={`${t('CLIENT_RECORD_NUMBER')}`}
            required={required}
            placeholder={`${t('CLIENT_RECORD_NUMBER')}${required ? '*' : ''}`}
            type="number"
            register={register('recordNumber', { ...validation, min: 1 })}
          />
        );
      }
      case 'gender': {
        return (
          <ClientSelect
            label={`${t('CLIENT_GENDER')}`}
            required={required}
            errorMsg={errors.gender?.message || ''}
            options={[
              { label: t('CLIENT_GENDER_UNKNOWN'), value: 0 },
              { label: t('CLIENT_GENDER_FEMALE'), value: 1 },
              { label: t('CLIENT_GENDER_MALE'), value: 2 },
            ]}
            control={control}
            rules={validation}
            name="gender"
          />
        );
      }
      case 'city': {
        return (
          <ClientInput
            label={`${t('CLIENT_CITY')}`}
            required={required}
            placeholder={`${t('CLIENT_CITY')}${required ? '*' : ''}`}
            register={register(name, validation)}
            errorMsg={errors[name]?.message}
            name={name}
          />
        );
      }
      case 'district': {
        return (
          <ClientInput
            label={`${t('CLIENT_DISTRICT')}`}
            required={required}
            placeholder={`${t('CLIENT_DISTRICT')}${required ? '*' : ''}`}
            register={register(name, validation)}
            errorMsg={errors[name]?.message}
            name={name}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div>
      {!isXLarge && <ReservationsSummary />}
      <St.Form onSubmit={handleSubmit(onSubmit)}>
        <St.Wrapper>
          <Paragraph
            size="subHeadline"
            weight="bold"
            className="modalTitle"
            color={theme.migrateColors('text.subtler', 'gray80')}
          >
            {`${t('STEP_3_TITLE')}`}
          </Paragraph>
          <StepTracker className="spacing" />
          <St.SubtitleBox>
            <Paragraph align="left" size="paragraph" weight="light" className="subtitleClient">
              {`${t('CLIENT_FORM_SUBTITLE')}`}
            </Paragraph>
          </St.SubtitleBox>
          <St.FormContainer>
            {isSalfa && (
              <>
                <St.ParagraphSubtitle>
                  <Paragraph align="left" size="subHeadline" weight="bold" color={theme.brandTheme.brand100}>
                    {t('VEHICLE_DATA')}
                  </Paragraph>
                </St.ParagraphSubtitle>
                <St.SalfaDataContainer>
                  <div>
                    <Paragraph align="left" size="paragraph" weight="bold" as="span">
                      {t('BRAND')}
                    </Paragraph>{' '}
                    <Paragraph align="left" size="paragraph" as="span">
                      {reservations.entity?.body?.marca}
                    </Paragraph>
                  </div>
                  <div>
                    <Paragraph align="left" size="paragraph" weight="bold" as="span">
                      {t('MODEL')}
                    </Paragraph>{' '}
                    <Paragraph align="left" size="paragraph" as="span">
                      {reservations.entity?.body?.modelo}
                    </Paragraph>
                  </div>
                  <div>
                    <Paragraph align="left" size="paragraph" weight="bold" as="span">
                      {t('PATENT')}
                    </Paragraph>{' '}
                    <Paragraph align="left" size="paragraph" as="span">
                      {reservations.entity?.body?.patente}
                    </Paragraph>
                  </div>
                  <div>
                    <Paragraph align="left" size="paragraph" weight="bold" as="span">
                      {t('YEAR')}
                    </Paragraph>{' '}
                    <Paragraph align="left" size="paragraph" as="span">
                      {reservations.entity?.body?.anio}
                    </Paragraph>
                  </div>
                  <div>
                    <Paragraph align="left" size="paragraph" weight="bold" as="span">
                      {t('CLIENT')}
                    </Paragraph>{' '}
                    <Paragraph align="left" size="paragraph" as="span">
                      {reservations.entity?.body?.nombre}
                    </Paragraph>
                  </div>
                  <div>
                    <Paragraph align="left" size="paragraph" weight="bold" as="span">
                      {t('LAST_MILEAGE')}
                    </Paragraph>{' '}
                    <Paragraph align="left" size="paragraph" as="span">
                      {reservations.entity?.body?.km}
                    </Paragraph>
                  </div>
                </St.SalfaDataContainer>
                <St.ParagraphSubtitle>
                  <Paragraph align="left" size="subHeadline" weight="bold" color={theme.brandTheme.brand100}>
                    {t('DRIVER_DATA')}
                  </Paragraph>
                </St.ParagraphSubtitle>
              </>
            )}
            <St.FormClientData>
              <ClientInput
                autoFocus
                label={t('CLIENT_FIRST_NAME')}
                required
                register={register('firstName', validationByField('simpleRequired', t))}
                errorMsg={errors.firstName?.message}
                isGtm
                id="button_client_name"
              />
              <ClientInput
                label={t('CLIENT_LAST_NAME')}
                required
                register={register('lastName', validationByField('simpleRequired', t))}
                errorMsg={errors.lastName?.message}
              />
              {bookingClientAttributes.map(ClientInputType)}
              {attributes &&
                attributes.length > 0 &&
                attributes.map((item: any) => Children.toArray(InputType(item, register, errors, control, t)))}
            </St.FormClientData>
            {isSalfa && (
              <>
                <St.ParagraphSubtitle>
                  <Paragraph align="left" size="subHeadline" weight="bold" color={theme.brandTheme.brand100}>
                    Información de ingreso
                  </Paragraph>
                </St.ParagraphSubtitle>
                <St.FormClientData>
                  <ClientInput
                    label={t('CURRENT_MILEAGE')}
                    register={register('recordHistory.currentMileage', {
                      required: { value: true, message: t('ERRORS.VALIDATION_REQUIRED') },
                      validate: (value) =>
                        value > recordHistory?.body.previousMileage || t('ERRORS.VALIDATION_MILEAGE'),
                    })}
                    errorMsg={errors.recordHistory?.currentMileage?.message}
                    placeholder="Ej: 120000"
                  />
                  <div />
                  <St.SelectContainer>
                    <ClientSelect
                      label={t('PRESENT_REPAIRS')}
                      required
                      errorMsg={errors.recordHistory?.presentsRepairs?.message || ''}
                      options={selectOptions}
                      control={control}
                      name="recordHistory.presentsRepairs"
                      rules={{
                        validate: (value) => {
                          if (value == null) {
                            return t('ERRORS.VALIDATION_REQUIRED');
                          }
                          return true;
                        },
                      }}
                      data-cy="presents_repairs"
                    />
                  </St.SelectContainer>
                  <div />
                  <St.SelectContainer>
                    <ClientSelect
                      label={t('PRESENT_CLAIMS')}
                      required
                      errorMsg={errors.recordHistory?.presentsClaims?.message || ''}
                      options={selectOptions}
                      control={control}
                      name="recordHistory.presentsClaims"
                      rules={{
                        validate: (value) => {
                          if (value == null) {
                            return t('ERRORS.VALIDATION_REQUIRED');
                          }
                          return true;
                        },
                      }}
                    />
                  </St.SelectContainer>
                  {presentsClaims && (
                    <St.SelectContainer>
                      <ClientInput
                        label={t('CLAIM_NUMBER')}
                        register={register('recordHistory.claimNumber', {
                          required: { value: presentsClaims, message: t('ERRORS.VALIDATION_REQUIRED') },
                          shouldUnregister: true,
                        })}
                        errorMsg={errors.recordHistory?.claimNumber?.message}
                      />
                    </St.SelectContainer>
                  )}
                </St.FormClientData>
                <St.ParagraphSubtitle>
                  <Paragraph align="left" size="subHeadline" weight="bold" color={theme.brandTheme.brand100}>
                    {t('BACKGROUND_CHECK')}
                  </Paragraph>
                </St.ParagraphSubtitle>
                <St.BackgroundCheckContainer>
                  <Controller
                    name="recordHistory.simpleSwornStatement"
                    control={control}
                    render={({ field: { value, onChange, name } }) => (
                      <St.CheckboxContainer>
                        <Checkbox
                          name={name}
                          checked={!!value}
                          onChange={() => {
                            onChange(!value);
                          }}
                        />
                        <Paragraph weight="light">{t('SIMPLE_SWORN_STATEMENT')}</Paragraph>
                      </St.CheckboxContainer>
                    )}
                  />
                  <Controller
                    name="recordHistory.vehicleDocuments"
                    control={control}
                    render={({ field: { value, onChange, name } }) => (
                      <St.CheckboxContainer>
                        <Checkbox name={name} checked={!!value} onChange={() => onChange(!value)} />
                        <Paragraph weight="light">{t('VEHICLE_DOCUMENTS')}</Paragraph>
                      </St.CheckboxContainer>
                    )}
                  />
                  <Controller
                    name="recordHistory.validLicense"
                    control={control}
                    render={({ field: { value, onChange, name } }) => (
                      <St.CheckboxContainer>
                        <Checkbox name={name} checked={!!value} onChange={() => onChange(!value)} />
                        <Paragraph weight="light">{t('VALID_LICENSE')}</Paragraph>
                      </St.CheckboxContainer>
                    )}
                  />
                  <Controller
                    name="recordHistory.complaintForm"
                    control={control}
                    render={({ field: { value, onChange, name } }) => (
                      <St.CheckboxContainer>
                        <Checkbox name={name} checked={!!value} onChange={() => onChange(!value)} />
                        <Paragraph weight="light">{t('COMPLAINT_FORM')}</Paragraph>
                      </St.CheckboxContainer>
                    )}
                  />
                  <Controller
                    name="recordHistory.validIdentityCard"
                    control={control}
                    render={({ field: { value, onChange, name } }) => (
                      <St.CheckboxContainer>
                        <Checkbox name={name} checked={!!value} onChange={() => onChange(!value)} />
                        <Paragraph weight="light">{t('VALID_IDENTITY_CARD')}</Paragraph>
                      </St.CheckboxContainer>
                    )}
                  />
                </St.BackgroundCheckContainer>
              </>
            )}
            {showNotes && (
              <ClientTextArea
                label={t('CLIENT_OBSERVATIONS')}
                placeholder={t('CLIENT_OBSERVATIONS_PLACEHOLDER')}
                register={register('notes')}
                errorMsg={errors?.notes?.message}
                gridItem="textarea"
              />
            )}
          </St.FormContainer>
          {policyTermActive ? (
            <Controller
              name="acceptedTerms"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, name } }) => (
                <St.BookingPolicy>
                  <Checkbox
                    checked={value}
                    name={name}
                    onChange={() => onChange(!value)}
                    data-testid="policy-terms-checkbox"
                  />
                  <St.PolicyLink
                    target="_blank"
                    href={companyBookingInfo.policyTerm ? companyBookingInfo.policyTerm : ''}
                  >
                    <Paragraph weight="light" size="small">
                      {`${t('I_ACCEPT_THE')} `}
                      <St.ColorSpan>{`${t('BOOKING_AND_PRIVACY_POLICY_OF')} ${companyOverview?.name}`}</St.ColorSpan>
                      {!isSalfa && ` ${t('AND_RECEIVE_EMAILS_BY_APRO')}`}
                    </Paragraph>
                  </St.PolicyLink>
                </St.BookingPolicy>
              )}
            />
          ) : (
            <>
              {isSalfa ? null : (
                <Paragraph className="bookingPolicy">{`${t('BY_BOOKING_YOU_ARE_ACCEPTING')}`}</Paragraph>
              )}
            </>
          )}
        </St.Wrapper>
        <St.CustomDrawer showDrawer>
          <St.DrawerContainer>
            <Button
              disabled={!isValid}
              type="submit"
              fullWidth
              className="button-continue"
              data-testid="button-continue"
              data-cy="client_form_button_continue"
            >
              {`${t('BUTTON_CONTINUE')}`}
            </Button>
          </St.DrawerContainer>
        </St.CustomDrawer>
        {/* Uncomment to see React-hook-forms dev tools
      <DevTool control={control} /> */}
      </St.Form>
    </div>
  );
};

export default ClientForm;
