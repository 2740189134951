import { FC, useCallback, useEffect, useState } from 'react';
import findLastIndex from 'lodash/findLastIndex';
import omit from 'lodash/omit';
import { MobileArrowLeft, MobileArrowRight } from '@agendapro/emerald-icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useMediaQuery } from 'react-responsive';

import { useReservation } from '@/context/ReservationsContext';
import { useDayLocale, usePageContext } from '@/hooks';
import { devices, Icon, Paragraph, Skeleton } from '@/UI';
import * as St from './DateSelector.styles';
import { Hour, useAvailableDays, useAvailableHours, useNextAvailableDay } from '@/services/serviceProviders';
import { availableDateMapper } from '@/services/serviceProviders/serviceProviders.map';
import { HourMapper } from './HourMapper/HourMapper';
import { deleteServiceFromBooking } from '@/utils';
import { NoResultsMessage } from '@/components/NoResultsMessage';
import scheduleLoader from '@/assets/scheduleLoader.gif';

const DateSelector: FC<{ handleNext?: () => void }> = ({ handleNext }) => {
  useDayLocale('', 'es');
  dayjs.extend(isBetween);
  const isDesktop = useMediaQuery({ query: devices.XLarge });
  const { reservations, dispatch } = useReservation();
  const [monthsDisplayed, setMonthsDisplayed] = useState<Array<string>>([]);
  const [requestedDatesArray, setRequestedDatesArray] = useState<Array<string>>([]);
  const { t } = useTranslation();
  const {
    bookingMode,
    canChooseProvider,
    companyBookingInfo,
    currentIndex,
    handleCloseNow,
    isSession,
    locationId,
    providerPreSelected,
    serviceBeingReserved,
    servicesIds,
    servicesToReserve,
    servicesWithProviders,
    setSelected,
  } = reservations;
  const { daySelected } = servicesToReserve[currentIndex];
  const [soonerDate, setSoonerDate] = useState<string>(daySelected || dayjs().format('YYYY-MM-DD'));
  const [{ startDate, endDate }, setRequestDates] = useState<{ startDate?: string; endDate?: string }>({
    startDate: undefined,
    endDate: undefined,
  });
  const { companyOverview } = usePageContext();

  const withProvidersObject = availableDateMapper.toObject({
    servicesWithProviders,
    servicesIds,
    isBundle: servicesToReserve[0].bundled,
  });
  const withProvidersArray = availableDateMapper
    .toArray({ servicesWithProviders, servicesIds, isBundle: servicesToReserve[0].bundled })
    .filter((item) => item !== null);

  const datesAndProviders = servicesToReserve.map((service) =>
    servicesIds?.findIndex((id) => id === service.internalId) !== 0 && service.start && service.providerId
      ? [service.start, service.end, service.providerId]
      : null,
  );

  const {
    isLoading: nextAvailableLoading,
    data: nextAvailableDay,
    isError,
  } = useNextAvailableDay({
    startDate: soonerDate,
    endDate: soonerDate,
    localId: locationId,
    services: JSON.stringify(servicesIds?.map((id) => id.split('-')[0])),
    providers: withProvidersObject,
    providersArray: JSON.stringify(withProvidersArray),
    datesAndProviders: JSON.stringify(datesAndProviders.filter((item) => item !== null)),
    bundled: serviceBeingReserved?.bundled ? 1 : 0,
    bundleId: serviceBeingReserved?.id && serviceBeingReserved?.bundled ? serviceBeingReserved?.id : 0,

    onSuccess: (data: { date: string; available: boolean }) => {
      const nextAvailableStartDate = data?.date;

      if (nextAvailableStartDate) {
        setRequestedDatesArray((state) => [...state, nextAvailableStartDate]);
        const nextAvailableEndDate = dayjs(nextAvailableStartDate)
          .add(isDesktop ? 13 : 6, 'day')
          .format('YYYY-MM-DD');

        setRequestDates({
          startDate: nextAvailableStartDate,
          endDate: nextAvailableEndDate,
        });
      }
    },
  });

  const { isLoading, data: days } = useAvailableDays({
    startDate,
    endDate,
    localId: locationId!,
    services: JSON.stringify(servicesIds?.map((id) => id.split('-')[0])),
    providers: withProvidersObject,
    providersArray: JSON.stringify(withProvidersArray),
    bundled: serviceBeingReserved?.bundled ? 1 : 0,
    bundleId: serviceBeingReserved?.id && serviceBeingReserved?.bundled ? serviceBeingReserved?.id : 0,
    onSuccess: (data) => {
      setMonths();
      const firstEnabledDayIndex = data.findIndex((day) => day.available);

      if (firstEnabledDayIndex !== -1 && !servicesToReserve[currentIndex]?.hourSelected) {
        selectDay(data[firstEnabledDayIndex]);
      }
      dispatch({ type: 'SET_DAYS_LOADING', payload: false });
    },
  });

  const selectedDateIndex = days && days.findIndex((day) => daySelected === day.date);

  const { data: availableHours, isLoading: isLoadingAvailableHours } = useAvailableHours({
    startDate: daySelected!,
    endDate: daySelected!,
    localId: locationId!,
    services: JSON.stringify(servicesIds?.map((id) => id.split('-')[0])),
    providers: withProvidersObject,
    providersArray: JSON.stringify(withProvidersArray),
    datesAndProviders: JSON.stringify(datesAndProviders.filter((item) => item !== null)),
    bundled: serviceBeingReserved?.bundled ? 1 : 0,
    bundleId: serviceBeingReserved?.id && serviceBeingReserved?.bundled ? serviceBeingReserved?.id : 0,
  });

  const notAvailableHoursAtAll =
    availableHours?.morningHours?.length === 0 &&
    availableHours?.afternoonHours?.length === 0 &&
    availableHours?.nightHours?.length === 0;

  const handleDaysAndFormat = (date: string, numberOfDays: number, isSubtract: boolean = false) =>
    isSubtract
      ? dayjs(date).subtract(numberOfDays, 'day').format('YYYY-MM-DD')
      : dayjs(date).add(numberOfDays, 'day').format('YYYY-MM-DD');

  const selectDay = useCallback(
    (day: { date: string; available: boolean }) => {
      dispatch({ type: 'SET_DAY_SELECTED', payload: day.date });
      dispatch({ type: 'SET_HOUR_SELECTED', payload: null });
      return dispatch({
        type: 'ADD_DATA_TO_CURRENT_SERVICE',
        payload: {
          serviceProvider:
            canChooseProvider === 'CANT' && companyOverview?.isPlanSolo === false
              ? { id: 0, publicName: t('FIRST_PROVIDER_AVAILABLE') }
              : providerPreSelected,
          dateFormatted: dayjs(day.date).format('LL'),
          daySelected: day.date,
          hourSelected: null,
        },
      });
    },
    [canChooseProvider, companyOverview?.isPlanSolo, dispatch, providerPreSelected, t],
  );

  const setMonths = () => {
    if (!!startDate && !!endDate) {
      if (dayjs(endDate).get('month') > dayjs(startDate).get('month')) {
        setMonthsDisplayed([dayjs(startDate).format('MMMM'), dayjs(endDate).format('MMMM')]);
      } else {
        setMonthsDisplayed([dayjs(startDate).format('MMMM')]);
      }
    }
  };

  const selectPreviousAvailableDay = async () => {
    const previousDayIndex = findLastIndex(days, 'available', selectedDateIndex! - 1);

    if (previousDayIndex !== -1) {
      dispatch({ type: 'SET_HOUR_SELECTED', payload: null });
      selectDay(days![previousDayIndex]);
    }
    const nonRepeatedDates = requestedDatesArray.filter((value, index, array) => array.indexOf(value) === index);
    const getClosestDate = (dates: string[], selectedDate: string) => {
      let closest;

      dates.some((day) => {
        const arrayDate = new Date(day);
        const currentDate = new Date(selectedDate);

        if (currentDate > arrayDate) {
          closest = day;
        }
        return false;
      });
      return closest;
    };
    const closestDate = getClosestDate(nonRepeatedDates, reservations.daySelected!);

    if (closestDate === undefined) {
      const currentDate = dayjs().day(1).format('YYYY-MM-DD');

      setRequestDates({
        startDate: currentDate,
        endDate: handleDaysAndFormat(currentDate, isDesktop ? 13 : 6),
      });
    } else {
      setRequestDates({
        startDate: closestDate,
        endDate: handleDaysAndFormat(closestDate, isDesktop ? 13 : 6),
      });
    }
  };

  const selectNextAvailableDay = async () => {
    const nextDay = days && days.find((day, index) => index > selectedDateIndex! && day.available);

    if (nextDay) {
      dispatch({ type: 'SET_HOUR_SELECTED', payload: null });
      selectDay(nextDay);
    }
    if (!nextDay) {
      setSoonerDate(dayjs(endDate).add(1, 'day').format('YYYY-MM-DD'));
    }
  };

  const getNextDays = () => {
    dispatch({ type: 'SET_DAYS_LOADING', payload: true });
    setRequestDates({
      startDate: handleDaysAndFormat(endDate!, 1),
      endDate: handleDaysAndFormat(endDate!, isDesktop ? 14 : 7),
    });
  };

  const getPreviousDays = () => {
    setRequestDates({
      startDate: handleDaysAndFormat(startDate!, isDesktop ? 14 : 7, true),
      endDate: handleDaysAndFormat(endDate!, isDesktop ? 14 : 7, true),
    });
  };

  const getIsFirstDate = (date) => {
    if (
      dayjs(charlyPromotionStartDate).isBefore(startDate) &&
      dayjs(startDate).isAfter(dayjs()) &&
      dayjs(startDate).isBefore(charlyPromotionEndDate)
    ) {
      return startDate === date;
    }
    if (dayjs(charlyPromotionStartDate).isBetween(startDate, endDate, 'day', '[]')) {
      return charlyPromotionStartDate === date;
    }
    return false;
  };

  const getIsLastDate = (date) => {
    if (!charlyPromotionStartDate) {
      return false;
    }
    if (dayjs(charlyPromotionEndDate).isAfter(endDate) || charlyPromotionEndDate === null) {
      return endDate === date;
    }
    if (charlyPromotionEndDate !== null) {
      return charlyPromotionEndDate === date;
    }
    return false;
  };

  const getInRange = (date) => {
    const endingDate = !!charlyPromotionStartDate && charlyPromotionEndDate === null ? endDate : charlyPromotionEndDate;

    return charlyPromotionStartDate ? dayjs(date).isBetween(charlyPromotionStartDate, endingDate, 'day', '[]') : false;
  };

  const getCharlyDiscountProps = (bookingMode, servicesToReserve, currentIndex) => {
    if (bookingMode === 'consecutive') {
      const serviceWithCharlyDiscount = servicesToReserve.find((item) => item.charlyDiscount);

      if (serviceWithCharlyDiscount) {
        const { charlyPromotionStartDate, charlyPromotionEndDate } = serviceWithCharlyDiscount;

        return { charlyPromotionStartDate, charlyPromotionEndDate };
      }
      return { charlyPromotionStartDate: '', charlyPromotionEndDate: '' };
    }
    const { charlyPromotionStartDate, charlyPromotionEndDate } = servicesToReserve[currentIndex];

    return { charlyPromotionStartDate, charlyPromotionEndDate };
  };

  const { charlyPromotionStartDate, charlyPromotionEndDate } = getCharlyDiscountProps(
    bookingMode,
    servicesToReserve,
    currentIndex,
  );

  const noResults = (noDaysAvailable) => (
    <St.NoResultsContainer noDaysAvailable={noDaysAvailable}>
      <St.StyledClock size={50} />
      <Paragraph weight="bold" size="subHeadline">
        {noDaysAvailable ? t('DATE_SELECTOR.NO_HOURS_NO_DAYS') : t('DATE_SELECTOR.NO_HOURS')}
      </Paragraph>
      {!noDaysAvailable && (
        <St.StyledButton onClick={selectNextAvailableDay}>{t('DATE_SELECTOR.BTN_NO_HOURS')}</St.StyledButton>
      )}
      {providerPreSelected && !noDaysAvailable && (
        <St.RemoveBtn
          noDaysAvailable={noDaysAvailable}
          onClick={() => dispatch({ type: 'REMOVE_PRE_SELECTED_PROVIDER' })}
        >
          <Paragraph weight="light">{t('DATE_SELECTOR.REMOVE_PRE_SELECTED')}</Paragraph>
        </St.RemoveBtn>
      )}
      {noDaysAvailable && (
        <St.RemoveBtn
          noDaysAvailable={noDaysAvailable}
          onClick={
            bookingMode === 'consecutive' && handleCloseNow
              ? () => {
                  setSelected([]);
                  handleCloseNow();
                }
              : () =>
                  deleteServiceFromBooking({
                    dispatch,
                    length: servicesToReserve.length,
                    handleClose: handleCloseNow,
                    service: servicesToReserve[currentIndex],
                  })
          }
        >
          <Paragraph weight="light">{t('DATE_SELECTOR.DELETE')}</Paragraph>
        </St.RemoveBtn>
      )}
    </St.NoResultsContainer>
  );

  useEffect(() => {
    if (
      !isSession &&
      !serviceBeingReserved?.bundled &&
      canChooseProvider !== 'CANT' &&
      !providerPreSelected &&
      bookingMode !== 'consecutive'
    ) {
      const removedProvider = omit(servicesWithProviders, servicesToReserve[currentIndex].internalId!);

      dispatch({
        type: 'SET_SERVICES_WITH_PROVIDERS',
        payload: { ...removedProvider },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedDateIndex = days && days.findIndex((day) => daySelected === day.date);
    const nextDay = days && days.find((day, index) => index > selectedDateIndex! && day.available);

    if (!daySelected && nextDay) {
      selectDay(nextDay);
    }
  }, [daySelected, selectedDateIndex, days, selectDay]);

  const daysInViewUnavailable = !days?.some((day) => day.available);

  const getMappedHours = ({ title, hours }: { title: string; hours: Hour[] }) =>
    hours.length ? (
      <>
        <St.TurnContainer>
          <Paragraph weight="light" size="small">
            {title}
          </Paragraph>
          <St.TurnDivision />
        </St.TurnContainer>
        <HourMapper hours={hours} handleNext={handleNext} />
      </>
    ) : null;

  const generateHourLoader = () => {
    const skeletonStyles = { height: 39, width: 74, marginTop: 12, marginBottom: 4, borderRadius: 4 };

    return Array.from({ length: 3 }, (_, i) => (
      <St.LoaderContainer key={i}>
        <Skeleton style={{ height: 1, width: '100%', marginTop: 24, marginBottom: 12 }} />
        <St.HourLoaderContainer>
          <Skeleton style={skeletonStyles} />
          <Skeleton style={skeletonStyles} />
          <Skeleton style={skeletonStyles} />
          <Skeleton style={skeletonStyles} />
        </St.HourLoaderContainer>
      </St.LoaderContainer>
    ));
  };

  if (
    ((!nextAvailableDay || nextAvailableDay === '') && !nextAvailableLoading) ||
    isError ||
    nextAvailableDay?.status === 202
  ) {
    return noResults(true);
  }

  return (
    <St.Wrapper>
      {(isLoading || nextAvailableLoading || monthsDisplayed.length === 0) && (!days || days.length === 0) ? (
        <St.DaysLoaderContainer>
          <NoResultsMessage
            image={scheduleLoader}
            message={[`${t('LOADER_MESSAGES.MESSAGE_1')}`, `${t('LOADER_MESSAGES.MESSAGE_2')}`]}
            height={115}
            width={140}
          />
        </St.DaysLoaderContainer>
      ) : (
        <>
          <St.DaySelectContainer charlyEndDate={!!charlyPromotionEndDate}>
            <St.CalendarHeader>
              <St.MonthsContainer>
                {monthsDisplayed.map((month) => (
                  <St.Month
                    className="month"
                    key={month}
                    isSelected={dayjs(reservations.daySelected).format('MMMM') === month}
                  >
                    <Paragraph size="sectionHeadline">{month}</Paragraph>
                  </St.Month>
                ))}
              </St.MonthsContainer>
              <St.OtherDatesContainer>
                <button
                  type="button"
                  onClick={selectPreviousAvailableDay}
                  disabled={isLoadingAvailableHours || isLoading || nextAvailableLoading}
                >
                  <Icon size="xxSmall" icon={<MobileArrowLeft />} className="arrowIcon" />
                </button>
                <Paragraph weight="light" className="otherDatesText">
                  {t('OTHER_DATES_AVAILABLE')}
                </Paragraph>
                <button
                  data-testid="nextAvailableDayButton"
                  type="button"
                  onClick={selectNextAvailableDay}
                  disabled={isLoadingAvailableHours || isLoading || nextAvailableLoading}
                >
                  <Icon size="xxSmall" icon={<MobileArrowRight />} className="arrowIcon" />
                </button>
              </St.OtherDatesContainer>
            </St.CalendarHeader>
            <St.SelectorContainer>
              <St.ArrowButton onClick={getPreviousDays} disabled={startDate === dayjs().format('YYYY-MM-DD')}>
                <MobileArrowLeft />
              </St.ArrowButton>

              {days?.map((day) => (
                <St.DiscountContainer
                  isInRange={getInRange(day.date)}
                  isFirstDate={getIsFirstDate(day.date)}
                  isLastDate={getIsLastDate(day.date)}
                  key={day.date}
                >
                  <St.DayContainer
                    isSelected={daySelected === day.date}
                    disabled={!day.available}
                    inRange={getInRange(day.date)}
                    onClick={() => selectDay(day)}
                  >
                    <St.Day>
                      <Paragraph weight="light">{dayjs(day.date).format('ddd').slice(0, -1)}</Paragraph>
                      <Paragraph weight="bold">{dayjs(day.date).format('DD')}</Paragraph>
                      {dayjs().format('YYYY-MM-DD') === day.date && <St.Dot />}
                    </St.Day>
                  </St.DayContainer>
                  {getIsLastDate(day.date) && <St.GiftTab />}
                </St.DiscountContainer>
              ))}
              <St.ArrowButton
                disabled={dayjs().add(companyBookingInfo.afterBooking, 'month').isBetween(endDate, startDate)}
                onClick={getNextDays}
              >
                <MobileArrowRight />
              </St.ArrowButton>
            </St.SelectorContainer>
            {charlyPromotionEndDate && (
              <St.CharlyText weight="light">{`${t('CHARLY.REMINDER')} ${dayjs(charlyPromotionEndDate).format(
                'DD/MM/YYYY',
              )}`}</St.CharlyText>
            )}
          </St.DaySelectContainer>

          <St.HoursContainer>
            <St.ScrollWrapper>
              {!availableHours || isLoading ? (
                generateHourLoader()
              ) : (
                <>
                  {!daysInViewUnavailable && (
                    <>
                      {getMappedHours({ title: t('DATE_SELECTOR.TIME_1'), hours: availableHours.morningHours })}
                      {getMappedHours({ title: t('DATE_SELECTOR.TIME_2'), hours: availableHours.afternoonHours })}
                      {getMappedHours({ title: t('DATE_SELECTOR.TIME_3'), hours: availableHours.nightHours })}
                    </>
                  )}
                  {!reservations.daysLoading && (notAvailableHoursAtAll || daysInViewUnavailable) && noResults(false)}
                </>
              )}
            </St.ScrollWrapper>
          </St.HoursContainer>
        </>
      )}
    </St.Wrapper>
  );
};

export default DateSelector;
