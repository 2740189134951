import Image from 'next/image';
import styled, { css } from 'styled-components';
import { applyEllipsisByNumOfLines, applyFlexAndCenter, devices } from '../../Styles';
import { InitialsAvatar } from '../InitialsAvatar';

export const Wrapper = styled.div`
  ${applyFlexAndCenter}
  flex-direction: column;
  width: 100%;
  @media ${devices.Large} {
    width: 66px;
  }
`;

export const AvatarWrapper = styled.div<{ small?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ small }) => (small ? '30px' : '42px')};
  height: ${({ small }) => (small ? '30px' : '42px')};
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.palette.white};
  margin-bottom: 2px;
  cursor: pointer;
  transition: 300ms ease-in-out;
  &:hover {
    border: 2px solid ${({ theme }) => theme.brandTheme.brand100};
  }
  @media ${devices['no-mobile']} {
    width: ${({ small }) => (small ? '30px' : '48px')};
    height: ${({ small }) => (small ? '30px' : '48px')};
  }
`;

export const CustomInitialsAvatar = styled(InitialsAvatar)<{ small?: boolean }>`
  min-width: ${({ small }) => (small ? '26px' : '38px')};
  min-height: ${({ small }) => (small ? '26px' : '38px')};
  width: ${({ small }) => (small ? '26px' : '38px')};
  height: ${({ small }) => (small ? '26px' : '38px')};
  @media ${devices['no-mobile']} {
    min-width: ${({ small }) => (small ? '26px' : '48px')};
    min-height: ${({ small }) => (small ? '26px' : '48px')};
    width: ${({ small }) => (small ? '26px' : '48px')};
    height: ${({ small }) => (small ? '26px' : '48px')};
  }
  ${({ small }) =>
    small &&
    css`
      background-color: ${({ theme }) => theme.brandTheme.brand100} !important;
      color: ${({ theme }) => theme.palette.white} !important;
      p {
        color: ${({ theme }) => theme.palette.white} !important;
      }
    `}
`;

export const NameContainer = styled.div`
  p {
    word-break: break-word;
    text-align: center;
    color: ${({ theme }) => theme.palette.grays100};
    ${applyEllipsisByNumOfLines(2)}
  }
`;

export const AvatarImg = styled(Image)`
  border-radius: 50%;
`;
